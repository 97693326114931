import React from "react"
import { PlusOutlined } from "@ant-design/icons"

interface PreviewImageProps {
  url: string
  name: string
}

function AddNewButton(props: PreviewImageProps): JSX.Element {
  const { url, name } = props

  return (
    <>
      <div className="flex-grow mb-5 flex flex-col justify-center w-full mx-auto">
        <div className="text-right">
          <a
            href={url}
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
            <PlusOutlined className="-ml-1 mr-2" />
            {name}
          </a>
        </div>
      </div>
    </>
  )
}

export default AddNewButton
