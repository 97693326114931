import React, { useMemo, useState } from "react"
import { Modal } from "antd"
import { pdfjs, Document, Page } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`
import 'react-pdf/dist/Page/TextLayer.css'

interface PreviewImageProps {
  height: number
  width: number
  src: string
  title: string
  base64?: boolean
}

function PreviewImage(props: PreviewImageProps): JSX.Element {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [pdfPreviewVisible, setPdfPreviewVisible] = useState(false)
  const { height, width, src, title, base64 } = props
  const presentHeight = height ? 0 : height
  const [numPages, setNumPages] = useState(null)
  const pdfFiles = ["pdf", "odf"]
  const imageFiles = ["jpg", "jpeg", "png", "gif", "webpg"]
  const campsCDNUrl = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  
  const ops = useMemo(() => {
    return   {
        cMapUrl: campsCDNUrl,
        cMapPacked: true,
      }
    
  }, [campsCDNUrl])

  return (
    <>
      {(pdfFiles.includes(src.split(".").pop().toLowerCase()) || base64) && (
        <>
          <Document
            file={src}
            onLoadSuccess={onDocumentLoadSuccess}
            loading="加载中"
            renderMode="canvas"
            options={ops}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                width={width}
                height={presentHeight}
                onClick={() => {
                  setPdfPreviewVisible(true)
                }}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
          <div className="clearfix">
            <Modal
              visible={pdfPreviewVisible}
              title={title}
              footer={null}
              width={800}
              onCancel={() => setPdfPreviewVisible(false)}>
              <Document
                file={src}
                onLoadSuccess={onDocumentLoadSuccess}
                loading="加载中"
                options={{
                  cMapUrl: campsCDNUrl,
                  cMapPacked: true,
                }}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    onClick={() => {
                      setPdfPreviewVisible(true)
                    }}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={750}
                    height={presentHeight}
                    loading="加载中"
                  />
                ))}
              </Document>
            </Modal>
          </div>
        </>
      )}
      {imageFiles.includes(src.split(".").pop().toLowerCase()) && (
        <>
          <img
            src={src}
            style={{ height: presentHeight, width, cursor: "pointer" }}
            onClick={() => setPreviewVisible(true)}
          />
          <div className="clearfix">
            <Modal
              visible={previewVisible}
              title={title}
              footer={null}
              width={800}
              onCancel={() => setPreviewVisible(false)}>
              <img
                alt={title}
                style={{ width: "100%", height: "auto" }}
                src={src}
              />
            </Modal>
          </div>
        </>
      )}
      {(!pdfFiles
        .concat(imageFiles)
        .includes(src.split(".").pop().toLowerCase()) && !base64) && (
        <p>该资源无法渲染</p>
      )}
    </>
  )
}

export default PreviewImage
