import React, { useState, useEffect } from "react"
import { Form, Input, Button, message } from "antd"
import { get, put, post } from "@rails/request.js"
import CropImageUploader from "../common/CropImageUploader"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

interface NewsFormProps {
  newsId: number
}

function NewsForm(props: NewsFormProps): JSX.Element {
  const { newsId } = props
  const [news, setNews] = useState<any>()
  const [newsBody, setNewsBody] = useState<any>()
  const isUpdate = !!newsId
  const [form] = Form.useForm()
  const newsIndexUrl = "/admin/news"
  const [uploadImage, setUploadImage] = useState<any>()

  const fetchNews = async () => {
    const response = await get(`/api/v1/admin/news/${newsId}`)
    if (response.ok) {
      const parsedData = await response.json
      setNews(parsedData)
      form.setFieldsValue(parsedData)
    } else {
      message.error(response.error)
    }
  }

  const onFinish = async (values: any) => {
    let response
    values.body = newsBody
    values.cover = uploadImage
    if (isUpdate) {
      response = await put(`/api/v1/admin/news/${newsId}`, {
        body: JSON.stringify(values),
      })
    } else {
      response = await post(`/api/v1/admin/news`, {
        body: JSON.stringify(values),
      })
    }
    if (response.ok) {
      message.success("提交成功")
      window.location.href = newsIndexUrl
    } else {
      console.log(response.message)
      message.error(response.message)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  const formatOutputHTML = (html) => html.replace(/&nbsp;/g, " ")

  useEffect(() => {
    if (isUpdate) fetchNews()
  }, [])

  return (
    <Form
      layout="vertical"
      initialValues={news}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}>
      <div className="card">
        <div className="card-header">
          <h3 className="card-header-title">
            {news?.id ? "修改企业" : "添加企业"}
          </h3>
          <div className="card-header-description">填写资讯</div>
        </div>

        <div className="card-body">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-12">
              <Form.Item
                label="标题"
                name="title"
                rules={[{ required: true, message: "请输入标题" }]}>
                <Input />
              </Form.Item>
            </div>

            <div className="col-span-12">
              <Form.Item label="封面图">
                <CropImageUploader
                  name="image_file"
                  srcName="news[cover]"
                  src={news?.cover}
                  width={400}
                  height={400}
                  onChange={(url) => setUploadImage(url)}
                />
              </Form.Item>
            </div>

            <div className="col-span-12">
              <Form.Item
                label="描述"
                name="description"
                rules={[{ required: true, message: "请输入描述" }]}>
                <Input.TextArea />
              </Form.Item>
            </div>

            <div className="col-span-12">
              <Form.Item label="详情">
                <CKEditor
                  editor={ClassicEditor}
                  data={news?.body}
                  onChange={(_, editor) => {
                    const data = editor.getData()
                    setNewsBody(formatOutputHTML(data))
                  }}
                  height={800}
                />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="space-x-2 card-footer text-right">
          <Form.Item>
            <a href={newsIndexUrl} type="text" className="btn mr-2">
              取消
            </a>

            <Button type="primary" htmlType="submit">
              确认提交
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}

export default NewsForm
