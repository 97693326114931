import React, { useState, useEffect } from "react"
import { Form, Input, Button, message, Checkbox } from "antd"
import { get, put, post } from "@rails/request.js"
import CropImageUploader from "../common/CropImageUploader"

interface UserFormProps {
  userId: number
}

function UserForm(props: UserFormProps): JSX.Element {
  const { userId } = props
  const [user, setUser] = useState<any>()
  const [uploadImage, setUploadImage] = useState<any>()
  const isUpdate = !!userId
  const [form] = Form.useForm()
  const userIndexUrl = "/admin/users"

  const fetchUser = async () => {
    const response = await get(`/api/v1/admin/users/${userId}`)
    if (response.ok) {
      const parsedData = await response.json
      setUser(parsedData)
      form.setFieldsValue(parsedData)
    } else {
      message.error(response.error)
    }
  }

  const onFinish = async (values: any) => {
    let response
    values.avatar = uploadImage
    if (isUpdate) {
      response = await put(`/api/v1/admin/users/${userId}`, {
        body: JSON.stringify(values),
      })
    } else {
      response = await post(`/api/v1/admin/users`, {
        body: JSON.stringify(values),
      })
    }

    const parsedData = await response.json

    if (response.ok) {
      message.success("提交成功")
      window.location.href = userIndexUrl
    } else {
      message.error(parsedData.message)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  useEffect(() => {
    if (isUpdate) fetchUser()
  }, [])

  return (
    <Form
      layout="vertical"
      initialValues={user}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}>
      <div className="card">
        <div className="card-header">
          <h3 className="card-header-title">
            {user?.id ? "修改用户" : "添加用户"}
          </h3>
          <div className="card-header-description">填写用户的真实信息</div>
        </div>

        <div className="card-body">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Form.Item
                label="用户昵称"
                name="full_name"
                rules={[{ required: true, message: "请输入用户昵称" }]}>
                <Input />
              </Form.Item>
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Form.Item label="用户头像">
                <CropImageUploader
                  name="image_file"
                  srcName="user[avatar]"
                  src={user?.avatar}
                  width={400}
                  height={400}
                  onChange={(url) => setUploadImage(url)}
                />
              </Form.Item>
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Form.Item
                label="手机号码"
                name="phone"
                rules={[{ required: true, message: "请输入手机号码" }]}>
                <Input />
              </Form.Item>
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Form.Item label="电子邮箱" name="email">
                <Input />
              </Form.Item>
            </div>

            {!isUpdate && (
              <>
                <div className="col-span-6 sm:col-span-3">
                  <Form.Item
                    label="登录密码"
                    name="password"
                    rules={[
                      { required: true, message: "请输入用户登录密码" },
                    ]}>
                    <Input />
                  </Form.Item>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <Form.Item
                    label="再次确认密码"
                    name="password_confirmation"
                    rules={[
                      { required: true, message: "请输入用户再次确认密码" },
                    ]}>
                    <Input />
                  </Form.Item>
                </div>
              </>
            )}

            <div className="col-span-6 sm:col-span-3">
              <Form.Item
                label="是否管理员"
                name="admin"
                valuePropName="checked">
                <Checkbox>是否管理员</Checkbox>
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="space-x-2 card-footer text-right">
          <Form.Item>
            <a href={userIndexUrl} type="text" className="btn mr-2">
              取消
            </a>

            <Button type="primary" htmlType="submit">
              确认提交
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}

export default UserForm
