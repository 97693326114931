import React, { useState } from "react"
import { Upload, message, Button } from "antd"
import { UploadOutlined } from "@ant-design/icons"

interface OcrUploadProps {
  companyId: string
  onChange: (text: string, imageUrl: string) => void
  btnClassName?: string
  targetId?: string
  targetType?: string
}

function OcrUpload(props: OcrUploadProps): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState()
  const { companyId, onChange, btnClassName, targetId, targetType } = props

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true)
      return
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        onChange(info.file.response.text, imageUrl)
        setImageUrl(imageUrl)
        setLoading(false)
      })
    }

    if (info.file.response.status === "ok") {
      message.success("发票识别成功，可在企业管理 -> 发票识别中查看")
      setLoading(false)
    } else {
      message.error("发票识别失败：" + info.file.response.message)
      setLoading(false)
      console.log(info.file.response.message)
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener("load", () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  return (
    <div className="my-2">
      <Upload
        name="invoice"
        showUploadList={false}
        action={`/api/v1/companies/${companyId}/invoice_ocrs?target_id=${targetId}&target_type=${targetType}`}
        onChange={handleChange}>
        {/* {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
        ) : (
          <div>
            <Button icon={<UploadOutlined />}>点击上传</Button>
          </div>
        )} */}
        <Button
          className={btnClassName}
          icon={<UploadOutlined />}
          loading={loading}
          disabled={loading}>
          {loading ? "正在识别发票" : "上传发票"}
        </Button>
      </Upload>
    </div>
  )
}

export default OcrUpload
