import React, { useState } from "react"
import QRCode from "react-image-charts-qrcode"
import { Button, Modal } from "antd"

interface Props {
  url: string
  name: string
}

function Qrcode(props: Props): JSX.Element {
  const { url, name } = props
  const [qrcodeVisiable, setQrcodeVisiable] = useState(false)

  return (
    <>
      <Button size="small" onClick={() => setQrcodeVisiable(true)}>{name}</Button>
      <Modal
        title={name}
        open={qrcodeVisiable}
        onOk={() => {
          setQrcodeVisiable(false)
        }}
        onCancel={() => {
          setQrcodeVisiable(false)
        }}
        okText="完成"
        cancelText="取消">
          <div className="flex justify-center">
            <QRCode
              data={url}
              size={200}
              framed
            />
          </div>
      </Modal>
    </>
  )
}

export default Qrcode
