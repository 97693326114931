import React, { useState, useEffect } from "react"
import { get } from "@rails/request.js"
import { Result, Button } from "antd"
import { SmileOutlined } from "@ant-design/icons"

function Dashboard(): JSX.Element {
  const [companies, setCompanies] = useState([])

  const fetchDatas = async () => {
    const response = await get(`/api/v1/companies`)
    if (response.ok) {
      const parsedData = await response.json
      setCompanies(parsedData.items)
    }
  }

  useEffect(() => {
    fetchDatas()
  }, [])

  return (
    <>
      <Result
        icon={<SmileOutlined />}
        subTitle="欢迎访问新代账，绑定企业后即可看到企业入口"
      />
      {companies.length > 0 && (
        <ul
          role="list"
          className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6">
          {companies.map((item) => (
            <li
              key={item.id}
              className="flex flex-col col-span-1 text-center bg-white border-2 border-gray-200 divide-y divide-gray-200 rounded-lg shadow-lg">
              <div className="flex flex-col flex-1 p-4">
                <img
                  className="flex-shrink-0 w-24 h-24 mx-auto rounded-full"
                  src={item.logo}
                  alt={item.name}
                />
                <h3 className="mt-6 text-sm font-medium text-gray-900">
                  {item.name}
                </h3>
              </div>
              <div>
                <div className="flex -mt-px divide-x divide-gray-200">
                  <div className="flex flex-1 w-0">
                    <a
                      href={`${item.slug}/invoice_ocrs`}
                      className="relative inline-flex items-center justify-center flex-1 w-0 py-4 -mr-px text-sm font-medium text-gray-700 border border-transparent rounded-bl-lg hover:text-gray-500">
                      <span>进入企业</span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default Dashboard
