import React, { useState, useEffect } from "react"
import OcrUpload from "components/common/OcrUpload"
import { List, Descriptions, Row, Col } from "antd"
import { get } from "@rails/request.js"
import { toQuery } from "utils/query"
import PreviewImage from "components/common/PreviewImage"

interface InvoiceOcrTargetsProps {
  companyId: string
  targetId: string
  targetType: string
}

function InvoiceOcrTargets(props: InvoiceOcrTargetsProps): JSX.Element {
  const { companyId, targetId, targetType } = props
  const [items, setItems] = useState([])
  const [tailMark, setTailMark] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const fetchItems = async (params = {}) => {
    if (!params["tail_mark"]) {
      params["tail_mark"] = tailMark || 1
    }
    const response = await get(
      `/api/v1/companies/${companyId}/invoice_ocrs?${toQuery(params)}`
    )
    if (response.ok) {
      const parsedData = await response.json
      setItems(parsedData.items)
      setTotalCount(parsedData.meta.total_count)
      setTailMark(parsedData.meta.tail_mark)
      setLoading(false)
    }
  }

  const handleListChange = (page) => {
    const params = {
      tail_mark: page,
      target_type: targetType,
      target_id: targetId,
    }
    fetchItems(params)
  }

  useEffect(() => {
    handleListChange(1)
  }, [])

  return (
    <>
      <OcrUpload
        companyId={companyId}
        targetId={targetId}
        onChange={() => handleListChange(1)}
        targetType={targetType}
      />

      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            handleListChange(page)
          },
          pageSize: 20,
          hideOnSinglePage: true,
        }}
        dataSource={items}
        locale={{
          emptyText: "暂无数据"
        }}
        renderItem={(item, index) => (
          <List.Item key={item.title}>
            <List.Item.Meta
              description={
                <Row gutter={16}>
                  <Col md={6}>
                    <PreviewImage
                      src={item.file_path}
                      width={280}
                      height={0}
                      title={item.name}
                    />
                  </Col>
                  <Col md={18}>
                    <Descriptions
                      title={`#${index + 1} ${item.sale_name}`}
                      layout="horizontal">
                      {[
                        { name: "销方税号", value: item.sale_tax_no },
                        { name: "发票种类", value: item.invoice_type_name },
                        { name: "发票号码", value: item.invoice_no },
                        { name: "发票代码", value: item.invoice_code },
                        { name: "开票日期", value: item.invoice_date },
                        { name: "含税总额", value: item.amount_tax },
                        { name: "不含税额", value: item.total_amount },
                        { name: "税额", value: item.total_tax },
                      ].map((i) => (
                        <Descriptions.Item
                          label={i.name}
                          key={item.name}
                          labelStyle={{
                            fontSize: "1rem",
                            color: "#6B7280",
                          }}
                          contentStyle={{
                            fontSize: "1rem",
                            color: "#374151",
                          }}>
                          {i.value}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  </Col>
                </Row>
              }
            />
            {item.content}
          </List.Item>
        )}
      />
    </>
  )
}

export default InvoiceOcrTargets
