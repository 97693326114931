import React, { useState, useEffect } from "react"
import { get, destroy } from "@rails/request.js"
import { Button, Popconfirm, Table, message } from "antd"
import { toQuery } from "utils/query"
import PreviewImage from "components/common/PreviewImage"

function AttachmentList(): JSX.Element {
  const [items, setItems] = useState([])
  const [tailMark, setTailMark] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const fetchItems = async (params = {}) => {
    if (!params["tail_mark"]) {
      params["tail_mark"] = tailMark || 1
    }
    const response = await get(`/api/v1/admin/attachments?${toQuery(params)}`)
    if (response.ok) {
      const parsedData = await response.json
      setItems(parsedData.data.items)
      setTotalCount(parsedData.data.meta.total_count)
      setTailMark(parsedData.data.meta.tail_mark)
      setLoading(false)
    }
  }

  const confirm = async (id: any) => {
    if (!id) return

    const response = await destroy(`/api/v1/admin/attachments/${id}`)
    if (response.ok) {
      message.success("删除成功")
      fetchItems({ tail_mark: tailMark - 1 })
    }
  }

  const cancel = () => {
    console.log(cancel)
  }

  const renderPath = (item: any) => {
    const imageRegex = /\.(jpg|jpeg|png|gif)$/i
    if (imageRegex.test(item.path)) {
      return (
        <PreviewImage
          src={item.path}
          width={240}
          height={60}
          title={`${item.target_type}#${item.target_id}`}
        />
      )
    } else {
      return <a href={item.path} target="_blank" className="text-blue-400">{item.path}</a>
    }
  }

  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      width: 80,
    },
    {
      title: "文件",
      key: "path",
      width: 200,
      render: renderPath,
    },
    {
      title: "关联资源",
      dataIndex: "target_type",
      key: "target_type",
      width: 80,
    },
    {
      title: "资源编号",
      dataIndex: "target_id",
      key: "target_id",
      width: 80,
    },
    {
      title: "企业编号",
      dataIndex: "company_id",
      key: "company_id",
      width: 50,
    },
    {
      title: "用户编号",
      dataIndex: "user_id",
      key: "user_id",
      width: 50,
    },
    {
      title: "操作",
      key: "action",
      width: 50,
      render: (item) => (
        <Popconfirm
          title="确认删除该资源？"
          onConfirm={() => confirm(item.id)}
          onCancel={cancel}
          okText="确认"
          cancelText="取消"
        >
          <Button type="link" danger>删除</Button>
        </Popconfirm>
      ),
    },
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    const params = { tail_mark: pagination.current }
    fetchItems(params)
  }

  useEffect(() => {
    fetchItems()
  }, [])

  return (
    <>
      <Table
        bordered
        loading={loading}
        columns={columns}
        pagination={{
          pageSize: 20,
          total: totalCount,
          showQuickJumper: true,
          showSizeChanger: false,
        }}
        rowKey="id"
        style={{ wordBreak: "keep-all" }}
        tableLayout="fixed"
        dataSource={items}
        onChange={handleTableChange}
      />
    </>
  )
}

export default AttachmentList
