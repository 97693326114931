import React, { useState, useEffect } from "react"
import { get, put } from "@rails/request.js"
import { Input, message, Card, Modal, Button } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
const { confirm } = Modal

function SettingsForm(): JSX.Element {
  const [settings, setSettings] = useState([])
  const [submitSettings, setSubmitSettings] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchSettings = async () => {
    const response = await get(`/api/v1/admin/settings`)
    if (response.ok) {
      const responseData = await response.json
      responseData.settings.map((s) => {
        s.disabled = true
      })
      setSettings(responseData.settings)
    } else {
      message.error("请求失败")
    }
  }

  const updateSetting = async () => {
    confirm({
      title: `请确认更新配置？更新后将会立即生效。`,
      icon: <ExclamationCircleOutlined />,
      okText: "确定",
      cancelText: "取消",
      async onOk() {
        setLoading(true)
        const response = await put(`/api/v1/admin/settings`, {
          body: JSON.stringify({ setting: submitSettings }),
        })

        if (response.ok) {
          message.success("更新成功")
          setLoading(false)
        } else {
          message.success("更新失败")
        }
      },
      onCancel() {
        console.log("Cancel")
      },
    })
  }

  useEffect(() => {
    fetchSettings()
  }, [])

  return (
    <>
      {settings.map((setting) => (
        <Card className="mt-25" key={setting.var} title={setting.name}>
          <div className="form-group">
            <Input
              className="form-control"
              defaultValue={setting.value}
              onChange={(e) => {
                setting.value = e.target.value
                setSubmitSettings([...settings])
              }}
            />
          </div>
        </Card>
      ))}

      {settings.length > 0 && (
        <Button
          type="primary"
          onClick={() => updateSetting()}
          loading={loading}>
          保存
        </Button>
      )}

      {settings.length === 0 && (
        <div className="text-center">暂无可用配置</div>
      )}
    </>
  )
}

export default SettingsForm
