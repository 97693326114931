import React, { FC, useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import locale from 'antd/es/date-picker/locale/zh_CN'


interface Props {
  name: string
  showTime?: boolean,
  wrapperClassName?: string,
  picker?: any
  defaultValue?: any
  size?: any
}

const BaseDatePicker: FC<Props> = ({
  name,
  showTime = false,
  wrapperClassName,
  picker = 'date',
  defaultValue,
  size = 'small'
}) => {
  const [selectDate, setSelectDate] = useState(null)

  const handleOnChange = (date: any, dateString: string) => {
    setSelectDate(dateString)
  }

  useEffect(() => {
    if (!!defaultValue) {
      setSelectDate(defaultValue)
    }
  }, [defaultValue])

  return (
    <div className={wrapperClassName}>
      {selectDate && <input hidden value={selectDate} name={name} />}
      <DatePicker
        value={selectDate && dayjs(selectDate)}
        placeholder="请选择日期"
        onChange={handleOnChange}
        showTime={showTime}
        picker={picker}
        showNow={true}
        locale={locale}
        size={size}
      />
    </div>
  )

}

export default BaseDatePicker
