import React, { useState, useEffect } from "react"
import { get } from "@rails/request.js"
import { Table, Form, Input, Button, Select } from "antd"
import { toQuery } from "utils/query"
const { Option } = Select

interface Props {
  ActionOptions: any
}

function AuditLogList(props: Props): JSX.Element {
  const { ActionOptions } = props
  const [items, setItems] = useState([])
  const [tailMark, setTailMark] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()

  const fetchItems = async (params = {}) => {
    if (!params["tail_mark"]) {
      params["tail_mark"] = tailMark || 1
    }
    const response = await get(`/api/v1/admin/audit_logs?${toQuery(params)}`)
    if (response.ok) {
      const parsedData = await response.json
      setItems(parsedData.items)
      setTotalCount(parsedData.meta.total_count)
      setTailMark(parsedData.meta.tail_mark)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "操作人",
      dataIndex: "user_name",
      key: "user_name",
      width: 100,
    },
    {
      title: "操作类型",
      dataIndex: "action_name",
      key: "action_name",
      width: 100,
    },
    {
      title: "操作时间",
      dataIndex: "created_at",
      key: "created_at",
      width: 100,
    },
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    const params = { tail_mark: pagination.current }
    fetchItems(params)
  }

  useEffect(() => {
    fetchItems()
  }, [])

  const handleSearch = (values) => {
    values.tail_mark = tailMark
    fetchItems(values)
  }

  const clearSearch = () => {
    form.resetFields()
  }

  return (
    <>
      <Form
        form={form}
        layout="inline"
        onFinish={handleSearch}
        className="mb-2"
      >
        <Form.Item label="模糊搜索" name="q">
          <Input placeholder="模糊搜索" />
        </Form.Item>
        <Form.Item label="操作类型" name="action_type">
          <Select
            placeholder="选择操作类型"
            allowClear
          >
            {ActionOptions.map((option) => (
              <Option value={option[1]}>{option[0]}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">搜索</Button>
          <Button type="default" className="ml-2" onClick={() => clearSearch()}>清楚</Button>
        </Form.Item>
      </Form>
      <Table
        bordered
        loading={loading}
        columns={columns}
        pagination={{
          pageSize: 20,
          total: totalCount,
          showQuickJumper: true,
          showSizeChanger: false,
        }}
        rowKey="id"
        style={{ wordBreak: "keep-all" }}
        tableLayout="fixed"
        dataSource={items}
        onChange={handleTableChange}
      />
    </>
  )
}

export default AuditLogList
