import * as React from "react"
import { get } from "@rails/request.js"
import { Result, Dropdown, Space, Menu } from "antd"

function Home(): JSX.Element {
  const [user, setUser] = React.useState({})
  const fetchDatas = async () => {
    const response = await get(`/api/v1/home.json`)
    if (response.ok) {
      const body = await response.text
      const parsedData = JSON.parse(body)
      setUser(parsedData.data.user)
    }
  }

  React.useEffect(() => {
    fetchDatas()
  }, [])

  const menu = () => {
    const items = [
      {
        key: "1",
        label: (
          <a rel="noopener noreferrer" href="/admin/dashboard">
            管理后台
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a rel="noopener noreferrer" href="/users/sign_in?v1=2">
            登录注册
          </a>
        ),
      },
    ]

    return <Menu items={items} />
  }

  return (
    <>
      <div className="overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px grid grid-cols-3 justify-items-center py-2 items-center">
        <h1>
          <a href="/dashboard">Home</a>
        </h1>
        <Dropdown overlay={menu}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>菜单 +</Space>
          </a>
        </Dropdown>
      </div>

      <Result
        status="403"
        title="欢迎访问！"
        // subTitle="请先在微信小程序绑定企业后再登录"
      />
    </>
  )
}

export default Home
