import React, { useEffect, useMemo, useState } from "react"
import { Form, Button, message } from "antd"
import { get } from "@rails/request.js"
import ModalForm from "components/common/form/ModalForm"
import { useToggle } from 'ahooks'

interface CompanyFormProps {
  companyId: number
}

function RoleForm(props: CompanyFormProps): JSX.Element {
  const { companyId } = props
  const [form] = Form.useForm()
  const [userOptions, setUserOptions] = useState([])
  const [checkers, setCheckers] = useState([])
  const [approvers, setApprovers] = useState([])
  const [admins, setAdmins] = useState([])
  const [open, { toggle }] = useToggle(false)

  const fetchCompanyRoleOptions = async () => {
    const response = await get(`/api/v1/admin/companies/${companyId}/role_options`)
    if (response.ok) {
      const parsedData = await response.json
      setUserOptions(parsedData.data.users)
      setCheckers(parsedData.data.checkers)
      setApprovers(parsedData.data.approvers)
      setAdmins(parsedData.data.admins)
    } else {
      message.error(response.error)
    }
  }

  useEffect(() => {
    if (!!companyId && open) {
      fetchCompanyRoleOptions()
    }
  }, [companyId, open])

  const fields = [
    {
      type: 'input',
      key: 'company_id',
      hidden: true,
    },
    {
      type: 'select',
      key: 'admins',
      label: '管理员',
      mode: 'multiple',
      options: userOptions
    },
    {
      type: 'select',
      key: 'checkers',
      label: '验收员',
      mode: 'multiple',
      options: userOptions
    },
    {
      type: 'select',
      key: 'approvers',
      label: '审核员',
      mode: 'multiple',
      options: userOptions
    }
  ]

  const data = useMemo(() => {
    return {
      company_id: companyId,
      checkers,
      approvers,
      admins
    }
  }, [companyId, checkers, approvers, admins])

  if (!companyId) return <></>

  return (
    <>
      <Button
        type='default'
        size="small"
        onClick={toggle}
      >
        管理员
      </Button>
      {open && (
        <ModalForm
          form={form}
          title='修改企业管理员'
          formData={fields}
          data={data}
          submitPath={`/api/v1/admin/companies/${companyId}/roles`}
          submitMethod='POST'
          reload={toggle}
          modalProps={{
            open: open
          }}
        />
      )}
    </>
  )
}

export default RoleForm
