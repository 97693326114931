import React, { useState, useEffect } from "react"
import { get } from "@rails/request.js"

function Dashboard(): JSX.Element {
  const [stats, setStats] = useState([])

  const fetchDatas = async () => {
    const response = await get(`/api/v1/admin/dashboard`)
    if (response.ok) {
      const parsedData = await response.json
      setStats(parsedData.stats)
    }
  }

  useEffect(() => {
    fetchDatas()
  }, [])

  return (
    <>
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {stats.map((item) => (
            <div
              key={item.name}
              className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">
                {item.name}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {item.stat}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </>
  )
}

export default Dashboard
