import React, { useState } from "react"
import { post } from "@rails/request.js"
import { Input, message, Modal, Form, Button } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
const { confirm } = Modal

const ticketFields = [
  {
    label: "项目",
    name: "xiangmu",
    rules: [{ required: false, message: "请输入" }],
    type: "input",
  },
  {
    label: "收款单位",
    name: "shoukuandanwei",
    rules: [{ required: false, message: "请输入" }],
    type: "input",
  },
  {
    label: "个人",
    name: "geren",
    rules: [{ required: false, message: "请输入" }],
    type: "input",
  },
  {
    label: "内容",
    name: "neirong",
    rules: [{ required: false, message: "请输入" }],
    type: "input",
  },
  {
    label: "金额大写",
    name: "jinedaxie",
    rules: [{ required: false, message: "请输入" }],
    type: "input",
  },
  {
    label: "金额小写",
    name: "jinexiaoxie",
    rules: [{ required: false, message: "请输入" }],
    type: "input",
  },
  {
    label: "备注",
    name: "beizhu",
    rules: [{ required: false, message: "请输入" }],
    type: "input",
  },
  {
    label: "付款单位",
    name: "fukuandanwei",
    rules: [{ required: false, message: "请输入" }],
    type: "input",
  },
  {
    label: "制单人",
    name: "zhidanren",
    rules: [{ required: false, message: "请输入" }],
    type: "input",
  },
]

function DeveloperList(): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const submitTicket = async (values: any) => {
    confirm({
      title: `是否发起钉钉工单审批？`,
      icon: <ExclamationCircleOutlined />,
      okText: "确定",
      cancelText: "取消",
      async onOk() {
        const ticket = ticketFields.map((x) => {
          return {
            name: x.label,
            value: values[x.name],
          }
        })
        setLoading(true)
        const response = await post(
          "/api/v1/admin/developers/dingtalk_ticket",
          {
            body: JSON.stringify({ ticket: ticket }),
          }
        )

        if (response.ok) {
          message.success("发起成功")
          setLoading(false)
        } else {
          message.success("发起失败")
        }
      },
      onCancel() {
        console.log("Cancel")
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  return (
    <Form
      layout="vertical"
      onFinish={submitTicket}
      onFinishFailed={onFinishFailed}
      form={form}>
      <div className="card">
        <div className="card-header">
          <h3 className="card-header-title">付款确认单审批</h3>
        </div>

        <div className="card-body">
          <div className="grid grid-cols-6 gap-6">
            {ticketFields.map((field) => {
              return (
                <div className="col-span-6 sm:col-span-3" key={field.name}>
                  <Form.Item
                    label={field.label}
                    name={field.name}
                    rules={field.rules}>
                    {field.type === "input" ? (
                      <Input />
                    ) : (
                      "暂未支持的输入类型"
                    )}
                  </Form.Item>
                </div>
              )
            })}
          </div>
        </div>

        <div className="space-x-2 card-footer text-right">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={false}>
              确认提交
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}

export default DeveloperList
