import React, { useState, useEffect } from "react"
import { Form, Input, Button, message } from "antd"
import { get, put, post } from "@rails/request.js"
import CropImageUploader from "../common/CropImageUploader"

interface CompanyFormProps {
  companyId: number
}

function CompanyForm(props: CompanyFormProps): JSX.Element {
  const { companyId } = props
  const [company, setCompany] = useState<any>()
  const [uploadImage, setUploadImage] = useState<any>()
  const isUpdate = !!companyId
  const [form] = Form.useForm()
  const companyIndexUrl = "/admin/companies"

  const fetchCompany = async () => {
    const response = await get(`/api/v1/admin/companies/${companyId}`)
    if (response.ok) {
      const parsedData = await response.json
      setCompany(parsedData)
      form.setFieldsValue(parsedData)
    } else {
      message.error(response.error)
    }
  }

  const onFinish = async (values: any) => {
    let response
    values.logo = uploadImage
    if (isUpdate) {
      response = await put(`/api/v1/admin/companies/${companyId}`, {
        body: JSON.stringify(values),
      })
    } else {
      response = await post(`/api/v1/admin/companies`, {
        body: JSON.stringify(values),
      })
    }

    if (response.ok) {
      message.success("提交成功")
      window.location.href = companyIndexUrl
    } else {
      console.log(response.message)
      message.error(response.message)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  useEffect(() => {
    if (isUpdate) fetchCompany()
  }, [])

  return (
    <Form
      layout="vertical"
      initialValues={company}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}>
      <div className="card">
        <div className="card-header">
          <h3 className="card-header-title">
            {company?.id ? "修改企业" : "添加企业"}
          </h3>
          <div className="card-header-description">填写企业的真实信息</div>
        </div>

        <div className="card-body">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Form.Item
                label="企业全称"
                name="name"
                rules={[{ required: true, message: "请输入企业全称" }]}>
                <Input />
              </Form.Item>
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Form.Item
                label="企业简称"
                name="alias"
                rules={[{ required: true, message: "请输入企业简称" }]}>
                <Input />
              </Form.Item>
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Form.Item
                label="唯一标识"
                name="slug"
                rules={[{ required: true, message: "请输入企业唯一标识" }]}>
                <Input />
              </Form.Item>
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Form.Item
                label="税号"
                name="tax_no"
                rules={[{ required: true, message: "请输入企业税号" }]}>
                <Input />
              </Form.Item>
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Form.Item label="Logo">
                <CropImageUploader
                  name="image_file"
                  srcName="company[logo]"
                  src={company?.logo}
                  width={400}
                  height={400}
                  onChange={(url) => setUploadImage(url)}
                />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="space-x-2 card-footer text-right">
          <Form.Item>
            <a href={companyIndexUrl} type="text" className="btn mr-2">
              取消
            </a>

            <Button type="primary" htmlType="submit">
              确认提交
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}

export default CompanyForm
