export const FormItemType = {
  Input: 'input',
  Textarea: 'textarea',
  Select: 'select',
  InputNumber: 'input-number',
  Switch: 'switch',
  Checkbox: 'checkbox',
  CheckboxGroup: 'checkbox-group',
  RadioGroup: 'radio-group',
  Radio: 'radio',
  Upload: 'upload',
  DatePicker: 'date-picker',
  DateRangePicker: 'date-range-picker',
}