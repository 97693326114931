/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { get, put } from "@rails/request.js"
import { Table, Tag, Space, Button, message, Modal, Form } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import PreviewImage from "components/common/PreviewImage"
import { toQuery } from "utils/query"
const { confirm } = Modal
import AddNewButton from "components/common/AddNewButton"
import UserBindCompanyForm from "./UserBindCompanyForm"

interface Props {
  companyOptions: any
}

function UsersList(props: Props): JSX.Element {
  const { companyOptions } = props
  const [users, setUsers] = useState([])
  const [tailMark, setTailMark] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const fetchUsers = async (params = {}) => {
    if (!params["tail_mark"]) {
      params["tail_mark"] = tailMark || 1
    }
    const response = await get(`/api/v1/admin/users?${toQuery(params)}`)
    if (response.ok) {
      const parsedData = await response.json
      setUsers(parsedData.users)
      setTotalCount(parsedData.meta.total_count)
      setTailMark(parsedData.meta.tail_mark)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      width: 100,
    },
    {
      title: "头像",
      key: "cover",
      width: 100,
      render: (item) =>
        item.cover ? (
          <PreviewImage
            src={item.cover}
            width={240}
            height={60}
            title={item.name}
          />
        ) : (
          "-"
        ),
    },
    {
      title: "昵称",
      key: "full_name",
      render: (item) => (
        <span>
          {item.full_name}{" "}
          <Tag color={item.status_color}>{item.status_name}</Tag>
        </span>
      ),
    },
    {
      title: "性别",
      dataIndex: "gender_name",
      key: "gender_name",
    },
    {
      title: "手机号",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "联系地址",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "操作",
      key: "action",
      width: 200,
      render: (item) => (
        <Space size={[8, 16]} wrap>
          <a
            href={`/admin/users/${item.id}/edit`}
            className="ant-btn ant-btn-sm">
            编辑
          </a>
          {item.status === "normal" && (
            <Button
              size="small"
              onClick={() => updateUserStatus(item.id, "blocked")}>
              屏蔽
            </Button>
          )}
          {item.status === "blocked" && (
            <Button
              size="small"
              onClick={() => updateUserStatus(item.id, "normal")}>
              取消屏蔽
            </Button>
          )}
          <UserBindCompanyForm
            user={item}
            companyOptions={companyOptions}
            defaultIds={item.company_ids}
          />
        </Space>
      ),
    },
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    const params = { tail_mark: pagination.current }
    fetchUsers(params)
  }

  const updateUserStatus = async (id, status) => {
    confirm({
      title: `请确认 ${
        status === "blocked" ? "屏蔽" : "取消屏蔽"
      } 编号为 ${id} 的用户吗？`,
      icon: <ExclamationCircleOutlined />,
      okText: "确定",
      cancelText: "取消",
      async onOk() {
        const response = await put(`/api/v1/admin/users/${id}`, {
          body: JSON.stringify({ status }),
        })

        if (response.ok) {
          message.success("更新成功")
          const u = users.find((pd) => pd.id === id)
          u.status = status
          u.status_name = status === "blocked" ? "屏蔽" : "正常"
          u.status_color = status === "blocked" ? "red" : "green"
          setUsers([...users])
        } else {
          message.success("更新失败")
        }
      },
      onCancel() {
        console.log("Cancel")
      },
    })
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <>
      <AddNewButton url="/admin/users/new" name="添加新的用户" />
      <Table
        bordered
        loading={loading}
        columns={columns}
        pagination={{
          pageSize: 20,
          total: totalCount,
          showQuickJumper: true,
          showSizeChanger: false,
        }}
        rowKey="id"
        style={{ wordBreak: "keep-all" }}
        tableLayout="fixed"
        dataSource={users}
        onChange={handleTableChange}
      />
    </>
  )
}

export default UsersList
