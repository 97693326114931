import React, { useState, useEffect } from "react"
import { get, put } from "@rails/request.js"
import { Table, Tag, Space, Button } from "antd"
import PreviewImage from "components/common/PreviewImage"
import { toQuery } from "utils/query"
import AddNewButton from "components/common/AddNewButton"
import OcrUpload from "components/common/OcrUpload"
import RoleForm from "./company/RoleForm"

function CompanyList(): JSX.Element {
  const [items, setItems] = useState([])
  const [tailMark, setTailMark] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const fetchItems = async (params = {}) => {
    if (!params["tail_mark"]) {
      params["tail_mark"] = tailMark || 1
    }
    const response = await get(`/api/v1/admin/companies?${toQuery(params)}`)
    if (response.ok) {
      const parsedData = await response.json
      setItems(parsedData.items)
      setTotalCount(parsedData.meta.total_count)
      setTailMark(parsedData.meta.tail_mark)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      width: 100,
    },
    {
      title: "Logo",
      key: "logo",
      width: 100,
      render: (item) => (
        item.logo ? (
          <PreviewImage
            src={item.logo}
            width={240}
            height={60}
            title={item.name}
          />
        ) : (
          "-"
        )
      ),
    },
    {
      title: "唯一标识",
      dataIndex: "slug",
      key: "slug",
      width: 100,
    },
    {
      title: "全称",
      dataIndex: "name",
      key: "name",
      width: 100,
    },
    {
      title: "别名",
      dataIndex: "alias",
      key: "alias",
      width: 100,
    },
    {
      title: "税号",
      dataIndex: "tax_no",
      key: "tax_no",
      width: 100,
    },
    {
      title: "操作",
      key: "action",
      width: 150,
      render: (item) => (
        <>
          <Space size={[8, 16]} wrap>
            <Button
              href={`/admin/companies/${item.id}/edit`}
              className="ant-btn ant-btn-sm">
              编辑
            </Button>
          
            <OcrUpload
              btnClassName="ant-btn ant-btn-sm"
              companyId={item.slug}
              onChange={(text, url) => console.log("text", text, "url", url)}
            />

            <RoleForm companyId={item.id} />
          </Space>
        </>
      ),
    },
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    const params = { tail_mark: pagination.current }
    fetchItems(params)
  }

  useEffect(() => {
    fetchItems()
  }, [])

  return (
    <>
      <AddNewButton url="/admin/companies/new" name="添加新的企业" />
      <Table
        bordered
        loading={loading}
        columns={columns}
        pagination={{
          pageSize: 20,
          total: totalCount,
          showQuickJumper: true,
          showSizeChanger: false,
        }}
        rowKey="id"
        style={{ wordBreak: "keep-all" }}
        tableLayout="fixed"
        dataSource={items}
        onChange={handleTableChange}
      />
    </>
  )
}

export default CompanyList
