import React, { useState } from "react"
import { Button, Modal, Form, Select, message } from "antd"
import { post } from "@rails/request.js"

interface Props {
  user: any
  companyOptions: any
  defaultIds: any
}

function UserBindCompanyForm(props: Props): JSX.Element {
  const { user, companyOptions, defaultIds } = props
  const [loading, setLoading] = useState(false)
  const [bindCompanyVisiable, setBindCompanyVisiable] = useState(false)
  const [form] = Form.useForm()

  const handleBindCompanyOk = async (values: any) => {
    setLoading(true)
    const response = await post(
      `/api/v1/admin/users/${user.id}/bind_company`,
      {
        body: JSON.stringify(values),
      }
    )

    if (response.ok) {
      message.success("绑定成功")
      setBindCompanyVisiable(false)
      setLoading(false)
      form.resetFields
    } else {
      message.error(response.error)
    }

    setLoading(false)
  }

  return (
    <>
      <Button size="small" onClick={() => setBindCompanyVisiable(true)}>
        绑定企业
      </Button>
      <Modal
        title="绑定企业"
        open={bindCompanyVisiable}
        onCancel={() => {
          setBindCompanyVisiable(false)
          form.resetFields()
        }}
        onOk={form.submit}
        okText="确认"
        cancelText="取消"
        confirmLoading={loading}>
        <Form
          layout="vertical"
          onFinish={handleBindCompanyOk}
          onFinishFailed={() => {
            setBindCompanyVisiable(false)
            form.resetFields()
          }}
          form={form}>
          <Form.Item
            label="绑定企业"
            name="company_ids"
            initialValue={defaultIds}>
            <Select
              showSearch
              placeholder="请选择绑定企业"
              optionFilterProp="children"
              mode="multiple"
              filterOption={(input: any, option: any) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={companyOptions}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default UserBindCompanyForm
